<template>
    <section class="recommend-game" :style="{padding: isPadding == 'true'?'0':'0 1.35rem'}">
        <h1 class="title">推荐游戏</h1>

        <div class="recommend-game-main">
            <div class="swiper-container" id="swiperRecommend">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="keys in pages">
                        <div class="game-item" v-for="item in recommendGameList[keys-1]">
                            <div class="game-item-box">
                                <el-avatar :size="120" shape="square">
                                    <img :src="item.ico"/>
                                </el-avatar>
                                <div class="game-info">
                                    <h2>{{item.game_name}}</h2>
                                    <p>{{item.game_desc | capitalize}}</p>
                                </div>
                                <a class="game-button" :href="'https://h5.yunjiwan.com/detail?gameId='+item.id" v-if="Public.isHttps() == true">
                                    打开
                                </a>
                                <a class="game-button" :href="'http://h5.yunjiwan.com/detail?gameId='+item.id" v-else>
                                    打开
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Swiper from '../assets/js/idangerous.swiper2.7.6.min.js'
export default {
    name: "RecommendGame",
    data () {
        return {
            game_icon: 'http://app.yunjiwan.com',
            recommendGameList: [],
            recommPage: 1,
            pages: 0
        }
    },
    created() {
        if(this.Public.isHttps()) {
            this.game_icon = 'https://app.yunjiwan.com'
        }
        this.getRecommendGame()
    },
    methods: {
        async getRecommendGame(){
            this.h5Request({
                url: "/api/h5/recommend_list/"
            }).then(result => {
                if(result.data.code == 0)
                {
                    var arrays = [];
                    result.data.data.forEach((item, value) => {
                        item.ico = this.game_icon + item.ico;
                        if(arrays.length == 6) {
                            this.recommendGameList.push(arrays)
                            arrays = []
                            arrays.push(item)
                        } else {
                            arrays.push(item)
                        }
                    })
                    this.recommendGameList.push(arrays)
                    this.recommPage += 1
                    this.pages = Math.ceil(result.data.data.length / 6)
                    setTimeout(() => {
                        new Swiper('#swiperRecommend', {
                            calculateHeight:true,
                            autoplayDisableOnInteraction: false,
                            grabCursor: true,
                            touchRatio: 0.5
                        });
                    }, 500);
                }
            })
        },
        openGame (gameId) {
            window.location.href = '/detail?gameId='+ gameId
            // this.$router.push('/detail?gameId='+gameId)
        }
    },
    props: [
        'isPadding'
    ],
    filters: {
        capitalize (value) {
            const cNumber = 27// 设置限定字数

            if (!value) return ''

            if (value.length > cNumber) {
                return value.slice(0, cNumber) + '...'
            }
            return value
        }
    }
}
</script>

<style scoped lang="less">
    .recommend-game {
        box-sizing: border-box;
        margin-top: 60px;

        .title {
            position: relative;
            font-size: 0.1875rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #151C24;
            box-sizing: border-box;
            padding-left: 0.125rem;
            margin-bottom: .1rem;
        }

        .title::before {
            content: '';
            position: absolute;
            left: 0;
            width: 0.04rem;
            height: 100%;
            background: #FF7431;
            opacity: 1;
            border-radius: 8px;
        }

        .recommend-game-main {
            width: 100%;

            .swiper-wrapper {
            }

            .swiper-slide {
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;

                .game-item {
                    width: 33.33%;
                    height: .833rem;
                    box-sizing: border-box;
                    padding: 0 0.04165rem;
                    margin-bottom: 0.08333rem;

                    .game-item-box {
                        display: flex;
                        position: relative;
                        width: 100%;
                        height: 100%;
                        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
                        border-radius: 8px;
                        box-sizing: border-box;
                        padding: .1rem;
                        background: #FFFFFF;

                        .el-avatar {
                            width: 0.625rem !important;
                            height: 0.625rem !important;
                            line-height: 0.625rem;
                        }

                        .game-info {
                            width: calc(100% - 120px);
                            box-sizing: border-box;
                            padding-left: 12px;

                            h2 {
                                width: 80%;
                                font-size: .17rem;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #151C24;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                            }

                            p {
                                margin-top: .1rem;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #212933;
                                -webkit-line-clamp: 3;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                            }
                        }

                        .game-button {
                            position: absolute;
                            right: 10px;
                            width: .31rem;
                            height: .17rem;
                            line-height: .17rem;
                            background: #FF7431;
                            border-radius: 4px;
                            text-align: center;
                            font-size: 0.073rem;
                            color: #FFFFFF;
                            cursor: pointer;
                        }
                    }
                }

                .game-item:nth-child(1), .game-item:nth-child(3n+1){
                    padding-left: 0;
                }
                .game-item:nth-child(3n+0) {
                    padding-right: 0;
                }
            }
        }
    }

    @media (max-width: 1300px) {
        .recommend-game .recommend-game-main .swiper-slide .game-item .game-item-box .game-info p {
            -webkit-line-clamp: 2;
        }
    }
</style>