import { render, staticRenderFns } from "./RecommendGame.vue?vue&type=template&id=ab2bc698&scoped=true&"
import script from "./RecommendGame.vue?vue&type=script&lang=js&"
export * from "./RecommendGame.vue?vue&type=script&lang=js&"
import style0 from "./RecommendGame.vue?vue&type=style&index=0&id=ab2bc698&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab2bc698",
  null
  
)

export default component.exports