<template>
    <div id="header">
        <section class="header-top">
            <div class="header-top-logo">
                <img src="../../assets/images/nav_img_logo.png" @click="$router.push('/')"/>
            </div>

            <div class="header-top-search">
                <div class="search-input">
                    <input type="text" placeholder="请输入内容" v-model="input">
                    <div class="search-btn" @click="search()">
                        <img src="../../assets/images/nav_icon_btn_shousuo_defult.png" />
                    </div>
                </div>

                <Login></Login>
            </div>
        </section>

<!--        <section class="swiper-main">-->
<!--            <div class="swiper-container" id="slideshow">-->
<!--                <div class="swiper-wrapper">-->
<!--                    <div class="swiper-slide" v-for="item in swiperimgList">-->
<!--                        <img :src="item.image" >-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </section>-->

    </div>
</template>

<script>
import Swiper from '../../assets/js/idangerous.swiper2.7.6.min.js'
import Login from "@/components/LoginDialog/Login";
export default {
    name: "Header",
    data () {
        return {
            game_icon: 'http://app.yunjiwan.com',
            input: null,
            // 轮播图
            swiperimgList: []
        }
    },
    created() {
        // this.getSwiperImg();
    },
    methods: {
        // 获取轮播图
        async getSwiperImg() {
            this.h5Request({
                url: '/api/h5/carousel/list/'
            }).then(result => {
                if(result.data.code == 0)
                {
                    result.data.data.forEach((item, value) => {
                        item.ico = this.game_icon + item.ico;
                        this.swiperimgList.push(item)
                    })

                    setTimeout(() => {
                        var mySwiper = new Swiper('#slideshow', {
                            autoplay: 300000,
                            calculateHeight:true,
                            autoplayDisableOnInteraction: false,
                            grabCursor: true,
                            touchRatio: 0.5,
                            loop: true
                        }, 300);
                    })
                }
            })
        },
        search () {
            if(this.input) {
                this.$router.push('/search?search='+this.input)
            }
        }
    },
    components: {
        Login
    }
}
</script>

<style scoped lang="less">

    #header {
        width: 100%;
        box-sizing: border-box;
        padding: 0 1.35rem;

        .header-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: .45833rem;

            .header-top-logo {
                width: 0.75rem;
                height: 0.167rem;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .header-top-search {
                display: flex;

                .search-input {
                    display: flex;
                    align-items: center;
                    input {
                        width: 2.08rem;
                        height: 0.25rem;
                        line-height: 0.25rem;
                        border-top-left-radius: .167rem;
                        border-bottom-left-radius: .167rem;
                        text-indent: .147rem;
                        font-size: .094rem;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #000333;
                        border: none;
                    }

                    .search-btn {
                        display: flex;
                        align-items: center;
                        height: 0.25rem;
                        background: #FFFFFF;
                        border-top-right-radius: .167rem;
                        border-bottom-right-radius: .167rem;
                        box-sizing: content-box;
                        padding-right: 0.0521rem;
                        img {
                            width: .25rem;
                            height: .25rem;
                        }
                    }
                }
            }
        }

        .swiper-main {
            width: 100%;
            border-radius: 16px;
            margin-top: 40px;

            .swiper-container {
                height: 100%;
            }

            .swiper-wrapper {
                height: 100% !important;
            }

            .swiper-slide {
                height: 100% !important;
                text-align: center;
            }

            img {
                display: inline-block;
                height: 2.083rem;
                border-radius: 5px;
            }
        }
    }

    @media (max-width: 768px) {

        .login-box {
            img {
                width: 1.2rem;
                height: 1.2rem;
            }
        }

        .el-message-box {
            width: 80% !important;
        }

        #header {
            padding: 10px;

            .header-top {
                height: 30px;

                .header-top-logo {
                    width: 3.2rem;
                    height: 100%;
                }

                .header-top-search {
                    height: 100%;
                    .search-input {
                        width: 100%;
                        height: 100%;
                        input {
                            width: 4.3rem;
                            height: 100%;
                            font-size: 0.4rem;
                        }
                        .search-btn {
                            height: 100%;
                            img {
                                width: .6rem;
                                height: .6rem;
                            }
                        }
                    }
                }
            }

            .swiper-main {
                margin-top: 20px;

                .swiper-slide {
                    width: 100%;
                    img {
                        width: 100%;
                        height: 3.967rem;
                    }
                }
            }
        }
        #header .header-top .header-top-search .header-login .el-avatar {
            width: .9rem !important;
            height: .9rem !important;
        }
    }
</style>