<template>
    <div>
        <div class="header-login" v-if="isPcShow">
            <el-avatar :size="80" v-show="!isLogin">
                <img :src="avatar" />
            </el-avatar>
            <el-dropdown trigger="click" @command="handleCommand">
                <el-avatar :size="80" v-show="isLogin">
                    <img :src="avatar" />
                </el-avatar>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>游玩历史</el-dropdown-item>
                    <el-dropdown-item command="outLogin">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <span @click="centerDialogVisible = true" v-show="!isLogin">登录</span>
            <LoginDialog :centerDialogVisible="centerDialogVisible" @childFun="childFun"></LoginDialog>
        </div>

        <div class="header-login" v-if="!isPcShow">
            <el-avatar :size="80" v-show="!isLogin">
                <img :src="avatar" @click="PCcenterDialogVisible = true"/>
            </el-avatar>

            <el-dropdown trigger="click" @command="handleCommand">
                <el-avatar :size="80" v-show="isLogin">
                    <img :src="avatar" />
                </el-avatar>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>游玩历史</el-dropdown-item>
                    <el-dropdown-item command="outLogin">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>

            <LoginDialog :PCcenterDialogVisible="PCcenterDialogVisible" @childFunPhone="childFunPhone"></LoginDialog>
        </div>
    </div>
</template>

<script>
import LoginDialog from "@/components/LoginDialog/LoginDialog";
export default {
    name: "Login",
    data () {
        return {
            centerDialogVisible: false,
            PCcenterDialogVisible: false,
            isLogin: this.$store.state.isLogin,
            avatar: require('../../assets/images/header.png'),
            isPcShow: false
        }
    },
    watch: {
        '$store.state.isLogin': function(item, value){
            this.isLogin = this.$store.state.isLogin
        },
        '$store.state.userInfo': function (item, value) {
            this.avatar = this.$store.state.userInfo.figureurl
        }
    },
    created() {
        // this.getSwiperImg();
        if(this.$store.state.userInfo.figureurl) {
            this.avatar = this.$store.state.userInfo.figureurl
        }
        this.isPc()
    },
    components: {
        'remote-js': {
            render(createElement) {
                return createElement('script',{attrs:{type:'text/javascript',src: "http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"}});
            },
            props:{
                src:{type:String, required:true}
            }
        },
        LoginDialog
    },
    methods: {
        outLogin () {
            var token = this.Public.getCookie('token')
            if(token) {
                this.$confirm('确定要忍心离开吗？', '登出提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // 通知后台退出登录  删除token todo
                    this.$store.commit('updateToken', null)
                    this.$store.commit('updateUserInfo', {'figureurl':require('../../assets/images/header.png')})
                    this.$store.commit('updateIsLogin', false)
                    this.Public.deleteCookie('token')
                    this.$message({
                        type: 'success',
                        message: '退出登录成功！'
                    });
                }).catch(() => {

                });
            }
        },
        isPc() {
            if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
                this.isPcShow = false
            }else{
                this.isPcShow =  true;
            }
        },
        PClogin () {
            if(!this.isLogin) {
                this.PCcenterDialogVisible = true
            } else {
                console.log(1)
            }
        },
        handleCommand(key) {
            if(key == 'outLogin') {
                this.outLogin()
            }
        },
        childFun(value) {
            this.centerDialogVisible = value
        },
        childFunPhone (value) {
            this.PCcenterDialogVisible = value
        }
    }
}
</script>
<style>
 @media (max-width: 768px) {
     .el-message-box {
         width: 80% !important;
     }
 }
</style>
<style scoped lang="less">
    .header-login {
        display: flex;
        align-items: center;
        margin-left: 0.156rem;

        .el-avatar {
            height: .32rem !important;
            width: .32rem !important;
            line-height: .32rem !important;
        }

        span {
            font-size: 0.147rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            margin-left: .1rem;
        }
    }

    @media (max-width: 768px) {

        .login-box {
            img {
                width: 1.2rem;
                height: 1.2rem;
            }
        }

        #header .header-top .header-top-search .header-login .el-avatar {
            width: .9rem !important;
            height: .9rem !important;
        }
    }
</style>